import getWeb3 from '@/utils/getWeb3'
import Web3 from 'web3'
import Mint from '@/json/Mint.json'
import MulticallAbi from '@/json/MulticallAbi.json'
import { chainNetwork } from '@/contants'

const state = {
    web3: {
      isInjected: null, 
      //web3Instance: false, 
      networkId: null, 
      account: null, 
      error: null, 
      initial: false 
    },
    user:{
        initial: false,
        inviteCount: 0,
        rate: 0.618,
        inviteRate: 0.309,
        totalClaim: 0,
        reward: 0,
        tokenBalance: 0,
    },
    inviters: [], 
    market: 0,
    ethBalance: 0,
}

const mutations = {
    registerWeb3Instance(state, payload) {
        const result = payload
        const web3Copy = state.web3
        web3Copy.account = result.account
        web3Copy.networkId = result.networkId
        web3Copy.isInjected = result.injectedWeb3
        web3Copy.web3Instance = result.web3
        web3Copy.initial = true
        state.web3 = web3Copy
    },
    disconnected(state, payload) {
        const web3Copy = state.web3;
        web3Copy.account = null;
        web3Copy.networkId = null;
        web3Copy.isInjected = null;
        web3Copy.web3Instance = null;
        web3Copy.initial = false;
        state.web3 = web3Copy
    },
    accountsChanged(state, payload) {
        state.web3.account = payload.account
    },
    chainChanged(state, payload) {
        state.web3.networkId = payload.networkId
    },
    updateUser(state, payload){
        const result = payload;
        state.user.initial = result.initial;
        state.user.inviteCount = result.inviteCount;
        state.user.totalClaim = result.totalClaim;
        state.user.reward = result.reward;
        state.user.tokenBalance = result.tokenBalance;
    },
    updateInviters(state, payload){
        state.inviters = payload;
    },
    updatrMarket(state, payload){
        state.market = payload;
    },
    updatrEthBalance(state, payload){
        state.ethBalance = payload;
    }
}

const actions = {
    async getMarketValue({commit}){
        const web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.base.org'));
        const path = ['0x4200000000000000000000000000000000000006','0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'];
        const amountIn = '1000000000000000000';
        let tokenCont = new web3.eth.Contract(MulticallAbi.abi, '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24');
        const amount = await tokenCont.methods.getAmountsOut(amountIn,path).call();
        commit('updatrMarket', (amount[1]/1e6));

        let weiBalance = await web3.eth.getBalance(chainNetwork[0].mintContract);
        weiBalance = parseFloat(web3.utils.fromWei(weiBalance, 'ether'));
        commit('updatrEthBalance', weiBalance);

    },
    async getPersion({ commit,state }) {
        
        console.log('获取信息：=',state.web3.account);
        if(!state.web3.account){
            return;
        }
        const web3 = new Web3(new Web3.providers.HttpProvider(chainNetwork[0].rpcUrls[0]));
        


        let tokenCont = new web3.eth.Contract(Mint.abi, chainNetwork[0].mintContract);
        const amount = await tokenCont.methods._currentAmount().call();
        const userInfo = {};
        userInfo.totalClaim = parseInt(web3.utils.fromWei(amount, 'ether'));
        const user = await tokenCont.methods._users(state.web3.account).call();
       
        userInfo.initial = user.initial;
        userInfo.inviteCount = user.inviteCount;
        
        const rewards = await tokenCont.methods.getRewards(state.web3.account).call();
        userInfo.reward = parseFloat(web3.utils.fromWei(rewards, 'ether')).toFixed(2);
       
        let balance = await tokenCont.methods.getRewards(state.web3.account).call();
        balance = parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(0);
        userInfo.tokenBalance = balance;
      
        console.log(userInfo);
        commit('updateUser', userInfo);
       
        const inviteCount = user.inviteCount < 100 ? user.inviteCount : 100;
        const inviterCalls = [];
        if(inviteCount > 0){
            for (let i = 0; i < inviteCount; i++) {
                const callData = web3.eth.abi.encodeFunctionCall({
                    name: '_invites',
                    type: "function",
                    "inputs": [
                        {
                        "name": "",
                        "type": "address"
                        },
                        {
                        "name": "",
                        "type": "uint256"
                        }
                    ]
                },[state.web3.account,i]);
                inviterCalls.push({target: chainNetwork[0].mintContract, callData});
            }
            const multicall = new web3.eth.Contract(MulticallAbi.abi, chainNetwork[0].multicallAddress);
            console.log(inviterCalls);
            const inviterList = await multicall.methods.tryAggregate(true,inviterCalls).call();
         
            const inviters = [];
            inviterList.forEach(obj => {
                console.log(obj.returnData);
                let p  = web3.eth.abi.decodeParameters(['address','uint256'],obj.returnData);
                console.log(p);
                inviters.push({
                    address:p[0],
                    datetime:p[1]
                });
            });
            console.log(inviters);
            commit('updateInviters', inviters);

        }
    },
    async getData({ commit,state }) {
     
        const web3 = new Web3(new Web3.providers.HttpProvider(chainNetwork[0].rpcUrls[0]));
        const contractInstance = new web3.eth.Contract(Mint.abi, chainNetwork[0].mintContract);
        let totalClaimable = await contractInstance.methods.totalClaimable().call().catch(() => {return '0'});
        totalClaimable = web3.utils.fromWei(totalClaimable, 'ether');
        let balance = 0;
        if(state.web3.initial){
            balance = await contractInstance.methods.userClaims(state.web3.account).call().catch(() => {return '0'});
            balance = web3.utils.fromWei(balance, 'ether');
        }
        commit('updateUser', {totalClaimable,balance})
    },
    registerWeb3({ commit }) {
        getWeb3().then(result => {
          commit('registerWeb3Instance', result)
        }).catch(e => {
          
        })
    
    
    },
  
    accountsChanged({ commit }, payload) {
        commit('accountsChanged', payload)
    },
 
    chainChanged({ commit }, payload) {
        commit('chainChanged', payload)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  