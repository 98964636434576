export const chainNetwork = [
  // 主网
  {
    chainId: 8453,
      chainName: 'Base Mainnet',
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
      rpcUrls: ['https://mainnet.base.org'],
      blockExplorerUrls: ['https://basescan.org/'],
      aliasName: 'ETH',
      nickName: 'ETH',
      mintContract: '0x00618855DB26c13d852155a34cF71AaA0fd73b34',
      multicallAddress: "0xf199ee4bb2074fdfc06d6c26dbdd9d4e46f2dc2b",
  },
  //测试网
  {
    chainId: 97,
    chainName: 'BSC Mainnet',
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
    rpcUrls: ['https://bsc-testnet.publicnode.com'],
    blockExplorerUrls: ['https://bscscan.com/'],
    aliasName: 'BNB',
    nickName: 'BNB',
    mintContract: '0x5Fe4d5f31D334DD750Fbfa859D47d1066f90Fe89',
    multicallAddress: "0x50E8C308ee045905466b77d41A41f4374dA25fAb",
  }
]